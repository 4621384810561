import { ThreeDSecureVerificationData, ThreeDSecureVerifyOptions } from "braintree-web/three-d-secure";
import { Api } from "../../Services/Api";
import appstore from "../../appStore";
import { Dispatch } from "../Dispatch";
import { CalculateExactAmountUserPaysInDollars } from "../Fare/FareHelper";
import { CreateBookingWithoutSmsVerification } from "../Booking/CreateBookingCommon";
import { LoadMyCards, SetDeviceData } from "../Payment/PaymentHelper";
import { ThreeDSecureFunnel } from "./ThreeDSecureFunnel";
import { ValidateGuestUser } from "../Verification/VerifyPhoneForBooking";
import { LoginStatusKind } from "../Authentication/AuthEntities";

/** Authenticating a payment card using 3D Secure verification. */
export namespace ThreeDSecure {

    /** 3DS client SDK from our Braintree SDK */
    let threeDSecureClient: braintree.ThreeDSecure | null = null;

    /**
     * Provide the completed 3DS SDK client after they are loaded.
     */
    export function SdkIsReady(threeDsClient: braintree.ThreeDSecure) {
        threeDSecureClient = threeDsClient;
    }

    /**
     * Performs the 3DS verification UI flow for the logged in users.
     * This method just addresses the loader UI concern. 
     */
    export async function Run3DsVerificationAndProceedBooking() {

        Dispatch.UILogicControl.ShowLoading();
        await LoadVaultedCardNonceAndProceedTo3DsVerifcationAndBooking();        
        Dispatch.UILogicControl.HideLoading();
    }

    /** Loads required input data to perform 3DS verification for logged in users and calls the actual 3DS verification method.  i.e. the card is already registered in MPS. Hence, needs an API call to MPS to get a nonce to trigger actual 3DS verification flow. */
    export async function LoadVaultedCardNonceAndProceedTo3DsVerifcationAndBooking() {
        ThreeDSecureFunnel.Start();

        const selectedCard = appstore.getState().booking.PaymentOption!.Card!; // all these are non-nullable at this point.

        // get nonce for the selected card
        var result = await Api.PaymentV2.GetVaultedCardNonce({ CardId: selectedCard.CardId });

        if (!result.isSuccess) {
            ThreeDSecureFunnel.ApiError("Get Vaulted Card Nonce", result, true);
            return;
        }

        await Run3DsVerificationAndProceedBooking2(result.value.Nonce, selectedCard.CardBin!); // cardBin is not null here.this is only nullable for wallet based payment methods like PayPal
    }

    /**
     * Performs the 3DS verification UI flow for the guest users. i.e. the card is not registered in MPS. But it is registered (tokenised) in Braintree. The tokenised nonce from Hosted Fields is used to trigger the 3DS verification flow and the tokenised nonce from 3DS will be used to register the card in MPS. 
     * This method only does the funnel log and calls the actual verification method. 
     */
    export async function Run3DsVerificationWithoutLoadingNonceAndProceedBooking(tokenisedNonce: string, bin: string) {
        ThreeDSecureFunnel.Start();
        await Run3DsVerificationAndProceedBooking2(tokenisedNonce, bin);
    }

    /** Performs 3DS verification flow. (opens the 3DS verifcation popup, verify card and process the output)  */
    export async function Run3DsVerificationAndProceedBooking2(nonce: string, cardBin: string) {

        const fareDetails = appstore.getState().condition.SelectedCondition.FareDetails;
        const tripCost = CalculateExactAmountUserPaysInDollars(fareDetails!, true, true);

        const threeDSecureParameters: ThreeDSecureVerifyOptions = {
            amount: tripCost.toFixed(2),
            nonce: nonce,
            bin: cardBin,
        };

        // set up listener after initialization
        threeDSecureClient!.on('lookup-complete', function (data?: ThreeDSecureVerificationData, next?: () => void) {
            if(next) next();
        });

        let customerCanceled = false;

        threeDSecureClient!.on('customer-canceled', function () {
            customerCanceled = true;
        });

        let threeDsVerifyPayload: braintree.ThreeDSecureVerifyPayload;

        try {
            threeDsVerifyPayload = await threeDSecureClient!.verifyCard(threeDSecureParameters);
        }
        catch (error) {
            ThreeDSecureFunnel.Exception("3DS Verify Card", error, true);
            return;
        }

        // even after the user closes the 3DS window without completing the verification, we get the nonce with the status 'challenge_required'. we shouldn't display error message in this case. but there are some other cases where the status is 'challenge_required' which are really errors and the error message should be displayed in these cases. therefore, exit early without error message (but log it to appinsights) if 'customer-cancelled' event is fired so the error message will be displayed in real error cases.
        if (customerCanceled) {
            ThreeDSecureFunnel.UserCancelled("3DS Verify Card");
            return;
        }

        if (threeDsVerifyPayload.threeDSecureInfo['status'] == 'authenticate_successful' || threeDsVerifyPayload.threeDSecureInfo['status'] == 'authenticate_attempt_successful') {            

            const loginState = appstore.getState().authentication.LoginStatus;

            if (loginState == LoginStatusKind.LoggedIn) {

                // we use this data in the create booking request only for the logged in users. for guest in users, we use this nonce to register the payment method, hence will not use in the booking request.
                Dispatch.Payment.Refresh3DSecure({ Upgraded3DsNonce: threeDsVerifyPayload.nonce, LiabilityShifted3Ds: threeDsVerifyPayload.threeDSecureInfo.liabilityShifted });

                await CreateBookingWithoutSmsVerification();

                // load the users cards list again to refresh the 3DS status of the updated card. loading this from the backend is the most reliable way to get the correct status. no need to await here
                LoadMyCards();

                // clear the 3DS nonce after use
                Dispatch.Payment.Clear3DSecure();
            }
            else {
                Dispatch.GuestPayment.CreditCard3DsVerified(threeDsVerifyPayload);
                await SetDeviceData();
                ValidateGuestUser();
            }            
        }
        else {
            ThreeDSecureFunnel.Exception("3DS Verify Card", { 'status': threeDsVerifyPayload.threeDSecureInfo['status'] }, true);
            return;
        }
    }
}