import { FailureResult } from "../../Services/ServiceEntities";
import { LogEvent } from "../../utils/LogEvent";
import { DialogKind } from "../Dialog/DialogEntities";
import { Dispatch } from "../Dispatch";
import { PaymentFunnelExitDetails } from "../Payment/PaymentFunnelExit";
import { WellKnownMessageKind } from "../Utils/ErrorMessages";

/** 
 * Records the user's passage through 3DS verification flow.
 */
export namespace ThreeDSecureFunnel {

    /** Signal the start of the 3DS funnel. i.e. the user selected a payment card that requires 3DS check and clicked Book button. */
    export function Start() {
        LogEvent.ThreeDSecureStart();
    }

    /** 
     * Record an exit from the 3DS funnel with a JavaScript or BrainTree error.
     * Since it comes from a JavaScript catch block, we can't type it properly. 
     */
    export function Exception(stageName: string, error: any, isUserAction: boolean) {

        const details = PaymentFunnelExitDetails.Exception(error);

        NoteFunnelExit(stageName, "Exception", details, isUserAction);
    }

    /** Record an exit from the 3DS funnel with a failed API call. */
    export function ApiError(stageName: string, apiError: FailureResult, isUserAction: boolean) {

        const details = PaymentFunnelExitDetails.ApiError(apiError);

        NoteFunnelExit(stageName, "API Error", details, isUserAction);
    }

    /** Record an exit from the 3DS funnel due to user cancelling the authentication flow. Do not display an error message in this case. */
    export function UserCancelled(stageName: string) {
        NoteFunnelExit(stageName, "User Cancelled", {}, false);
    }

    /**
     * The point is to record where along the flow the user dropped off.
     * Also displays an error message asking the user to try a different payment method.
     */
    function NoteFunnelExit(stageName: string, errorKind: string, details: Record<string, string>, isUserAction: boolean) {

        details.IsUserAction = String(isUserAction);
        LogEvent.ThreeDSecureFunnelExit(stageName, errorKind, details);

        if (isUserAction) {
            Dispatch.Dialog.SetSimpleErrorMessage(WellKnownMessageKind.CardCouldNotBeVerified);
            Dispatch.Dialog.ShowDialog(DialogKind.ErrorMessageWithNoTitle);
        }
    }
}