import { FailureResult } from "../../Services/ServiceEntities";
import { SetErrorMessages } from "../Utils/CommonHelpers";

/** Various methods to generate appinsights data for errors occur during different stages of adding payment methods. */
export namespace PaymentFunnelExitDetails {

    /** Returns error details in the format to be logged in to appinsights in case of an exception occurred during adding a payment method. */
    export function Exception(error: any): Record<string, string> {

        const details: Record<string, string> = {};

        if (error) {
            if (error.name) details.name = error.name;
            if (error.code) details.code = error.code;
            if (error.message) details.message = error.message;
            if (error.type) details.type = error.type;
            if (error.status) details.status = error.status;
        }

        return details;
    }

    /** Returns error details in the format to be logged in to appinsights in case of an API error occurred during adding a payment method. */
    export function ApiError(apiError: FailureResult): Record<string, string> {

        const summary = SetErrorMessages(apiError);

        const details: Record<string, string> = {
            message: summary.errorMessage,
        };

        return details;
    }
}
